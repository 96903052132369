<template>
  <div class="body">
    <div class="noticeTitles">公告列表</div>
    <div class="noticeBox">
      <div class="lies" v-for="(item,index) in lists" :key="index" @click="toNoticeDetail(item.id)">
        <span class="lies_span lies_title">{{ item.title }}</span>
        <span class="lies_span">{{ item.createDateTime|dateFormat }}</span>
      </div>
    </div>

<!--    <pagination-->
<!--      class="pages"-->
<!--      :total="total"-->
<!--      :page.sync="queryInfo.current"-->
<!--      :limit.sync="queryInfo.size"-->
<!--      @pagination="getNotices"-->
<!--    />-->

  </div>
</template>
<script>
// import Pagination from '@/components/Pagination/index'

export default {
  // components: { Pagination },
  data () {
    return {
      total: 0,
      lists: [],
      queryInfo: {
        current: 1,
        size: 20,
        keyword: ''
      }
    }
  },
  created () {
    this.getNotices()
  },
  methods: {
    getNotices () {
      this.axios.get(`${this.getaway.oa}srm/notices/getAll`, {
        params: {
          current: 1,
          size: 20,
          keyword: ''
        }
      }).then((res) => {
        this.lists = res.data.records
        this.total = parseInt(res.data.total)
      })
    },
    toNoticeDetail (id) {
      this.$router.push({
        path: '/noticeDetail',
        query: {
          id
        }
      })
    },
  }
}
</script>

<style scoped>

.body {
}

.noticeTitles {
  text-align: center;
  margin: 20px;
  height: 50px;
  line-height: 50px;
}

.pages {
  width: 460px;
  margin: 10px auto;
}

.noticeBox {
  background-color: #F6F6F6;
  width: 950px;
  margin: 10px auto;
  padding: 10px 100px;
}

.lies {
  padding: 10px;
  height: 30px;
  line-height: 30px;

  display: flex;
  justify-content: space-between;
}

.lies_span {
  display: inline-block;
}

.lies_title {

  width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
